import resume from '../assets/pdf/Umesh_Resume.pdf'
import umesh from "../assets/jpg/umesh-kumar-mishra.jpg"

export const headerData = {
    name: 'Umesh Kumar Mishra',
    title: "Full Stack Developer",
    desciption:"Highly skilled full stack developer with expertise in a range of programming languages and frameworks. I am committed to staying up-to-date with the latest trends and technologies, and have a talent for finding elegant solutions to complex technical challenges. I work closely with my clients to deliver exceptional results. ",
    image: umesh,
    resumePdf: resume
}
