export const experienceData = [
    {
        id: 1,
        company: 'Sujanix Pvt Ltd.',
        jobtitle: 'React Fullstack Developer',
        startYear: '2022',
        endYear: 'Present'
    }
    ,
    {
        id: 2,
        company: 'B.I.V. technologies',
        jobtitle: 'junior software developer',
        startYear: '2021',
        endYear: '2022'
    },
]