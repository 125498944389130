export const educationData = [
    {
        id: 1,
        institution: 'City High School',
        course: 'Board of secodary education',
        startYear: '2009',
        endYear: '2014'
    },
    {
        id: 2,
        institution: 'Aaryan gurukul residential college',
        course: 'Higher secondary education',
        startYear: '2014',
        endYear: '2016'
    },
    {
        id: 3,
        institution: 'Berhampur University',
        course: 'Bachelor of science (Computer Science)',
        Honors:'Computer Science',
        startYear: '2016',
        endYear: '2019'
    },
    {
        id: 4,
        institution: 'National Institute of science and technology',
        course: 'Master of Computer Application (MCA)',
        startYear: '2019',
        endYear: '2021'
    },
]